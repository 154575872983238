cs.ns("app.ui.trait.root.websocketservices");
app.ui.trait.root.websocketservices.model = cs.trait({
    protos: {

        create () {
            this.base();
            cs(this).model({
                "state:webSocketConnected": { value: false, valid: "boolean" }
            })
        }

    }
});